<template>
    <parent-layout title="Dashboard">
        <div v-if="hasUser && !hasRole('ROLE_ILLUMINATE_EMPLOYEE')">
            <v-btn class="primary" dark outlined :to="{ name: 'client-communication-redirect' }">
                <v-icon left>fal fa-cogs</v-icon>API Setup
            </v-btn>
        </div>
        <div v-else>
            <span v-if="!hasDistrict"> Select a district to continue </span>
            <div v-else>
                <v-row>
                    <v-col>
                        <dashboard-card
                            title-icon="fal fa-building"
                            text="Total Clients - Coming Soon!"
                        ></dashboard-card>
                    </v-col>
                    <v-col>
                        <dashboard-card
                            title-icon="fal fa-desktop-alt"
                            text="Total Instances - Coming Soon!"
                        ></dashboard-card>
                    </v-col>
                    <v-col>
                        <dashboard-card
                            title-icon="fal fa-school"
                            text="Total Schools - Coming Soon!"
                        ></dashboard-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <dashboard-card
                            title-icon="fal fa-user-friends"
                            text="Total Users - Coming Soon!"
                        ></dashboard-card>
                    </v-col>
                    <v-col>
                        <dashboard-card
                            title-icon="fal fa-user-graduate"
                            text="Total Students - Coming Soon!"
                        ></dashboard-card>
                    </v-col>
                    <v-col>
                        <dashboard-card
                            title-icon="fal fa-chalkboard-teacher"
                            text="Total Teachers - Coming Soon!"
                        ></dashboard-card>
                    </v-col>
                </v-row>
            </div>
        </div>
    </parent-layout>
</template>

<script>
import DashboardCard from '@/components/cards/DashboardCard'
import ParentLayout from '@/components/layout/ParentLayout'
import { mapGetters } from 'vuex'

export default {
    components: {
        DashboardCard,
        ParentLayout,
    },
    computed: {
        ...mapGetters({
            hasDistrict: 'hasDistrict',
            hasRole: 'auth/hasRole',
            hasUser: 'auth/hasUser',
        }),
    },
}
</script>
