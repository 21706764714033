<template>
    <v-card outlined flat class="flex pa-8">
        <v-card-title class="justify-center"
            >{{ title }}
            <v-icon v-if="titleIcon" color="primary"> {{ titleIcon }} </v-icon>
        </v-card-title>
        <v-card-text class="justify-center align-center text-center"> {{ text }} </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        titleIcon: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: '',
        },
    },
}
</script>